import PageHeader from '@/components/PageHeader.vue'
import $request from '@/request/contactsapi.js'
const contactsIndex = {
  name: 'contactsIndex',
  components: {
    PageHeader
  },
  data() {
    var repwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请设置密码'))
      }
      if (value !== this.userInfo.Pwd) {
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      fullscreenLoading: false, // 全局loading
      rules: {
        NickName: [{ required: true, message: '请填写人员名称', trigger: 'blur' }],
        EMail: [{ required: true, message: '请填写人员邮箱', trigger: 'blur' }],
        Mobile: [{ required: true, message: '请填写人员手机', trigger: 'blur' }],
        Sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        JobName: [{ required: true, message: '请填写人员职务', trigger: 'change' }],
        // 确认密码
        repwd: [
          { validator: repwd, trigger: 'change' }
        ]
      },
      userKeywords: '',
      activeName: 'first',
      // 全部人员
      useracttiveid: 1,
      useracttiveInfo: null,
      allUserData: [
        {
          id: 1, //
          name: '全部人员',
          count: 1,
          allcount: 5
        },
        {
          id: 2, //
          name: '禁用成员',
          count: 2,
          allcount: 5
        }
        // {
        //   id: 3, //
        //   name: '未分配部门成员',
        //   count: 3,
        //   allcount: 5
        // }
        // {
        //   id: 4, //
        //   name: '历史成员账号',
        //   count: 4,
        //   allcount: 5
        // }
      ],
      // 角色
      roleacttiveid: 0,
      roleacttiveInfo: null,
      roleUserData: [
        {
          IDX: 1,
          RoleName: '销售员',
          RoleMemberCount: 2
        }
      ],
      // 角色弹框
      addroleVisible: false,
      roleInfo: {
        IDX: 0,
        RoleName: '',
        RoleDesc: '',
        UserList: [
          {
            UserID: 119590,
            username: 'monica'
          },
          {
            UserID: 119580,
            username: 'phoebe'
          }
        ] // 角色下的人员
      },
      // 角色添加人员
      addroleuserVisible: false,
      // 已添加的人员
      addedUserData: [
        {
          userId: 1,
          username: 'monica'
        },
        {
          userId: 2,
          username: 'phoebe'
        },
        {
          userId: 3,
          username: 'rachel'
        },
        {
          userId: 4,
          username: 'chandler'
        }
      ],
      // 新增部门
      adddeptVisible: false,
      deptacttiveid: 0,
      deptacttiveInfo: null,
      deptinfo: {
        GroupName: '',
        GroupDesc: '',
        SimsSectionID: '', // 部门编号
        DepartmentLeadersID: 119580, // 部门主管
        DepartmentLeadersName: 'phoebe', // 名称
        ChargeLeaderID: 119590, // 分管领导
        ChargeLeaderName: 'monica', // 分管领导
        AllowAt: '0',
        IsDefault: '0',
        ParentID: 0 // 上级部门
      },
      // UserList
      UserList: [{
        name: 'monica',
        deptname: '销售部 '
      },
      {
        name: 'phoebe',
        deptname: '销售部 '
      },
      {
        name: 'rachel',
        deptname: '销售部 '
      },
      {
        name: 'ross',
        deptname: '销售部 '
      },
      {
        name: 'joey',
        deptname: '销售部 '
      },
      {
        name: 'chandler',
        deptname: '销售部 '
      }],
      UserList2: [{
        name: 'monica',
        deptname: '销售部 '
      },
      {
        name: 'phoebe',
        deptname: '销售部 '
      },
      {
        name: 'rachel',
        deptname: '销售部 '
      },
      {
        name: 'ross',
        deptname: '销售部 '
      },
      {
        name: 'joey',
        deptname: '销售部 '
      }],
      // 部门
      deptdata: [{
        value: 1,
        label: '销售部fsa11ds',
        count: 111,
        ischecked: false,
        children: [{
          value: 4,
          label: '销售部1',
          count: 2,
          ischecked: false,
          children: [{
            value: 9,
            count: 3,
            label: '销发送售部2',
            ischecked: false
          }, {
            value: 10,
            count: 5,
            label: '销售部3',
            ischecked: false
          }]
        }]
      }],
      // 新增人员
      editUserInfo: {
        boxtitile: '新增人员',
        IsEdit: false
      }, // 编辑人员框 状态
      adduserVisible: false,
      userInfo: {
        UserID: 0,
        NickName: '', // 人员名称
        UserName: '', // 账号
        ExeID: '', // 用户编号
        Pwd: 'fa',
        repwd: 'fdfdsasa ',
        EMail: '',
        Mobile: '',
        Sex: null,
        JobName: '',
        usernum: '',
        OfficeTel: '', // 办公电话
        QQ: '', // qq号
        WorkNum: '', // 工号
        PermissionGroupInfoList: [], // 部门
        IsValid: '1', // 是否启用(1：启用 0：不启用)
        RoleList: [],
        LeaderUserID: 119580, // 直属领导,
        LeaderUserName: 'fsda', // 直属领导
        UserClass: 2 // 是否设置为管理员 2 普通账户  1 超级管理员
      },
      userdeptsdata: [
        {
          value: 2219,
          label: 'Saas部门fdsfdsa  ',
          children: [{ value: 22112,
            label: 'fdsaffdfa'
          }]
        }
      ], // 新增人员 部门数据源
      usercheckeddeptitem: {
        deptvalue: null,
        sourceData: [
          {
            id: 2,
            value: 2219,
            label: 'fdsafsfsdafddfasa ',
            children: [{ value: 22112,
              label: 'fdsaffdfa'
            }]
          }
        ],
        userdepttype: '0',
        userdepthandleChange: 'userdepthandleChange'
      }, // 每一个选择部门数据源
      usercheckeddeptarr: [
        // {
        //   deptvalue: null,
        //   sourceData: [
        //     {
        //       id: 2,
        //       value: 2219,
        //       label: 'Saas部门fds ',
        //       children: [{ value: 22112,
        //         label: 'fdsaffdfa'
        //       }]
        //     }
        //   ],
        //   userdepttype: '0',
        //   userdepthandleChange: 'userdepthandleChange'
        // },
        // {
        //   deptvalue: null,
        //   sourceData: [
        //     {
        //       id: 1,
        //       value: 2219,
        //       label: 'test12313',
        //       children: [{ value: 22112,
        //         label: 'fdsaffdfa'
        //       }]
        //     }
        //   ],
        //   userdepttype: '0',
        //   userdepthandleChange: 'userdepthandleChange'
        // }
      ],
      // 新增人员直属领导
      addedUserLeaderData: [{
        userId: 1,
        username: 'monica'
      }],
      // 新增人员 角色datalist
      userrolesDataList: [
        {
          idx: 1,
          rolename: '1法定分散fsdaf 2321'
        },
        {
          idx: 2,
          rolename: 'fdsa'
        }
      ],
      // 选择人员
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员
      selectUserkeywords: '',
      selectUserDeptData: [{
        value: 1,
        label: '销售部fsa11ds',
        count: 111,
        ischecked: false,
        children: [{
          value: 4,
          label: '销售部1',
          count: 2,
          ischecked: false,
          children: [{
            value: 9,
            count: 3,
            label: '销发送售部2',
            ischecked: false
          }, {
            value: 10,
            count: 5,
            label: '销售部3',
            ischecked: false
          }]
        }]
      }], // 选择人员 部门数据源
      selectUserroleData: [
        {
          idx: 1,
          name: '销售员',
          count: 2,
          ischecked: false
        },
        {
          idx: 2,
          name: '订单员',
          count: 2,
          ischecked: false
        },
        {
          idx: 3,
          name: '采购员',
          count: 2,
          ischecked: false
        }
      ], // 选择人员 角色数据源
      selectUserallData: [], // 选择人员 部门以及人员数据源
      // 查询所有人员
      UserListloading: false,
      userListParas: {
        CompanyID: -1,
        DeptID: -1,
        RoleID: -1,
        UserName: '',
        QueryType: 1, // 搜索类型 1:全部成员 2:禁用成员 3:未分配部门成员 4:历史成员账号
        PageIndex: 1,
        PageSize: 10
      },
      // 查询部门人员列表
      UserdeptList: [],
      UserdeptListloading: false,
      userdeptListParas: {
        CompanyID: -1,
        DeptID: -1,
        RoleID: -1,
        UserName: '',
        QueryType: -1, // 搜索类型 1:全部成员 2:禁用成员 3:未分配部门成员 4:历史成员账号
        PageIndex: 1,
        PageSize: 10
      },
      // 选择成员
      connuserlist: [], // 成员
      connuserdeptlist: [], // 部门
      connuserrolelist: [], // 角色
      isshow: false,
      selectuserType: 0, // 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时
      checkeduserIdList: [], // 选中的人员
      checkeduserList: [], // 选中的人员(复选框)
      isshowpassword: true,
      activeNameOld: 'first'
    }
  }, // data ends
  mounted() {
    this.getUserList()
    this.getdeptList()
    this.getRoledata()
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  methods: {
    // 人员权限
    dealUserPower(_data) {
      // 传入dept ,部门id this.deptacttiveid
      this.adduserVisible = false
      this.$router.push({ name: 'jurisdictionIndex', params: { type: 'user', idx: _data.UserID, info: this._data.NickName }})
    },
    // 角色权限
    dealRolePower() {
      // 传入dept ,部门id this.deptacttiveid
      this.$router.push({ name: 'jurisdictionIndex', params: { type: 'role', idx: this.roleacttiveid, info: this.roleacttiveInfo.label }})
    },
    // 部门权限
    dealDeptPower() {
      // 传入dept ,部门id this.deptacttiveid
      this.$router.push({ name: 'jurisdictionIndex', params: { type: 'dept', idx: this.deptacttiveid, info: this.deptacttiveInfo.label }})
    },
    // 行状态
    usertableRowClassName({ row, rowIndex }) {
      if (row.IsValid === 0) {
        return 'userrowColor'
      }
      return ''
    },
    // 部门 批量移除人员
    removeuserFromdept(_userIdList) {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionDeptUserDelete({ deptId: _this.deptacttiveid, userIdList: _userIdList }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getUserdeptList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 部门 全部移除
    removealluserFromdept() {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionDeptUserDeleteAll({ IDX: _this.deptacttiveid }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getUserdeptList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 角色 全部移除
    removealluserFromrole() {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionRoleUserDeleteAll({ IDX: _this.roleacttiveid }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getUserroleList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 角色 批量移除人员
    removeuserFromrole(_userIdList) {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionRoleUserDelete({ roleId: _this.roleacttiveid, userIdList: _userIdList }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getUserroleList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // _type 1 批量选中 2： 全部
    dealremoveuser(_type, _userid) {
      const _this = this
      if (_type === 2) {
        if (_this.roleacttiveid > 0) {
          _this.removealluserFromrole()
        } else if (_this.roleacttiveid > 0) {
          _this.removealluserFromdept()
        }
      } else if (_type === 1) {
        const _userIdList = []
        if (_userid > 0) {
          _userIdList.push(_userid)
        } else {
          _this.checkeduserList.forEach((ele) => {
            _userIdList.push(ele.UserID)
          })
        }
        if (_this.roleacttiveid > 0) {
          _this.removeuserFromrole(_userIdList)
        }
        if (_this.deptacttiveid > 0) {
          _this.removeuserFromdept(_userIdList)
        }
      }
    },
    // 禁用 启用 人员 _isValid 状态:1：启用 0：禁用
    enableUser(_isValid, _userIdList) {
      const _this = this
      _this.fullscreenLoading = true
      $request.UpdateUserStatus({ isValid: _isValid, userIdList: _userIdList }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
        // this.useracttiveid = 0
        // this.roleacttiveid = 0
        // this.deptacttiveid = val
          if (_this.useracttiveid > 0) {
            _this.getUserList()
          } else if (_this.roleacttiveid > 0) {
            _this.getUserroleList()
          } else if (_this.deptacttiveid > 0) {
            _this.getUserdeptList()
          }
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    dealenableUser(_isValid, _userid) {
      const _this = this
      const _userIdList = []
      let isgoing = true
      if (_userid > 0) {
        _userIdList.push(_userid)
      } else {
        _this.checkeduserList.forEach((ele) => {
          if (ele.UserClass === 1) {
            isgoing = false
            _this.$message.error('超级管理员（' + ele.NickName + '）不能被禁用！！')
            return false
          }
          _userIdList.push(ele.UserID)
        })
      }
      if (isgoing) {
        this.enableUser(_isValid, _userIdList)
      }
    },
    // 清空通讯录
    deleteallUser() {
      const _this = this
      _this.fullscreenLoading = true
      $request.EmptyAddressBook().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getUserList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 选中人员 chekckbox
    userlisthandleSelectionChange(val) {
      console.log(val)
      this.checkeduserList = val
    },
    // 加载人员信息
    userrowclick(row) {
      const _this = this
      _this.usercheckeddeptarr = []
      _this.fullscreenLoading = true
      $request.GetUserByUserId({ userId: row.UserID }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.dealUserInfo(_this, res.Message)
          _this.adduserVisible = true
        }
      })
    },
    // 加载信息时 处理人员 角色 以及部门
    dealUserInfo(_this, data) {
      _this.userInfo = data
      console.log(_this.userInfo)
      _this.userInfo.userroles = []
      // 处理角色
      const rolelist = data.RoleList
      rolelist.forEach((ele) => {
        _this.userInfo.userroles.push(ele.IDX)
      })
      // 处理部门
      const deptlist = data.PermissionGroupInfoList
      deptlist.forEach((ele) => {
        _this.usercheckeddeptarr.push({
          sourceData: _this.deptdata,
          deptvalue: ele.TreeIDList,
          userdepttype: ele.UserIdentity,
          userdepthandleChange: 'userdepthandleChange'
        })
      })
    },
    // 确认选中人员
    doconnuser() {
      const _this = this
      // selectuserType 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时部门主管 5：新增部门时分管领导 6: 编辑人员 直属领导
      switch (_this.selectuserType) {
        case 1:
          _this.doroleconnuser()
          break
        case 2:
          _this.dodeptconnuser()
          break
        case 3:
          _this.doeditroleconnuser()
          break
        case 4:
          _this.doeditdeptconnuser()
          break
        case 5:
          _this.doeditdeptconnuser2()
          break
        case 6:
          _this.doedituserconnuser()
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // 编辑人员 直属领导
    doedituserconnuser() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('直属领导只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.userInfo.LeaderUserID = _this.checkeduserIdList[0].IDX
      _this.userInfo.LeaderUserName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 编辑部门的时候 确认选中部门主管
    doeditdeptconnuser2() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('分管领导只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.deptinfo.ChargeLeaderID = _this.checkeduserIdList[0].IDX
      _this.deptinfo.ChargeLeaderName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 编辑部门的时候 确认选中部门主管
    doeditdeptconnuser() {
      const _this = this
      if (_this.checkeduserIdList.length > 1) {
        _this.$message('部门主管只能选择一个人！，当前选中人数：' + _this.checkeduserIdList.length)
        return false
      }
      _this.deptinfo.DepartmentLeadersID = _this.checkeduserIdList[0].IDX
      _this.deptinfo.DepartmentLeadersName = _this.checkeduserIdList[0].label
      _this.seletUserVisible = false
    },
    // 编辑角色的时候 确认选中人员
    doeditroleconnuser() {
      const _this = this
      _this.checkeduserIdList.forEach((ele) => {
        _this.roleInfo.UserList.push({ UserID: ele.IDX, NickName: ele.label })
      })
      _this.seletUserVisible = false
    },
    // 请求接口关联(部门与人员)
    dodeptconnuser() {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionDeptUserSave({ deptId: this.deptacttiveid, userIdList: this.checkeduserIdList }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.seletUserVisible = false
          _this.getUserdeptList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 请求接口关联(角色与人员)
    doroleconnuser() {
      const _this = this
      _this.fullscreenLoading = true
      $request.PermissionRoleUserSave({ roleId: this.roleacttiveid, userIdList: this.checkeduserIdList }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.seletUserVisible = false
          _this.getUserroleList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      _this.pushuserarr(ischecked, item)
      if (item.children) {
        _this.dgcheck(ischecked, item, 1)
      }
      console.log(this.checkeduserIdList)
    },
    // 处理角色选择人
    editrolecheckeduser(ischecked, data) {
      if (ischecked) {
        if (this.checkeduserIdList.indexOf(data) < 0) {
          this.checkeduserIdList.push(data)
        }
      } else {
        this.checkeduserIdList.splice(this.checkeduserIdList.indexOf(data), 1)
      }
    },
    // 部门 / 角色 添加成员
    selectcheckeduser(ischecked, data) {
      if (ischecked) {
        if (this.checkeduserIdList.indexOf(data.IDX) < 0) {
          this.checkeduserIdList.push(data.IDX)
        }
      } else {
        this.checkeduserIdList.splice(this.checkeduserIdList.indexOf(data.IDX), 1)
      }
    },
    // 获取
    checkedDept(ischecked, val, item) {
      const _this = this
      if (item.children) {
        _this.dgcheck(ischecked, item, -1)
      }
    },
    //
    pushuserarr(ischecked, data) {
      const _this = this
      switch (_this.selectuserType) {
        case 1:
          _this.selectcheckeduser(ischecked, data)
          break
        case 2:
          _this.selectcheckeduser(ischecked, data)
          break
        case 3:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 4:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 5:
          _this.editrolecheckeduser(ischecked, data)
          break
        case 6:
          _this.editrolecheckeduser(ischecked, data)
          break
        default: console.log('未找到需要关联人员的组织！！_type：' + _this.selectuserType)
      }
    },
    // pushArrytype  -1 不需要push  1 push到 checkeduserIdList  数组里
    dgcheck(ischecked, data, pushArrytype) {
      const _this = this
      data.ischecked = ischecked
      if (pushArrytype === 1) {
        _this.pushuserarr()
      }
      if (data.children) {
        data.children.forEach(element => {
          return _this.dgcheck(ischecked, element, pushArrytype)
        })
      }
    },
    // selectUseractiveNamehandleClick
    selectUseractiveNamehandleClick(val) {
      console.log(val)
    },
    // 新增人员时，设置角色
    userroleschange(val) {
      console.log(val)
    },
    // 新增人员部门 初始化数据
    userdeptpush() {
      // sourceData:{
      //   value: null,
      //   label: '',
      //   children: null
      // }
      const arr = {
        deptvalue: null,
        sourceData: this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      }
      this.usercheckeddeptarr.push(arr)
    },
    // 删除新增人员部门数组
    removesuerdeptarr(_index) {
      this.usercheckeddeptarr.splice(_index, 1)
    },
    // 新增人员选中部门
    userdepthandleChange(value, index) {
      console.log(value)
    },
    // 移除角色人员
    removeUser(_index) {
      console.log(_index)
      this.addedUserData.splice(_index, 1)
    },
    handleClick() {
      if (this.activeName !== this.activeNameOld) {
        this.UserList = []
        this.UserdeptList = []
      }
      this.activeNameOld = this.activeName
    },
    // 格式化百分比
    formatUserprecent(percentage) {
      return ''
    },
    // 选中查询人员的类
    CheckedUserType(_id, data) {
      this.roleacttiveid = 0
      this.roleacttiveInfo = null
      this.deptacttiveid = 0
      this.deptacttiveInfo = null
      this.useracttiveid = _id
      this.getUserList()
    },
    // 获取人员信息
    getUserList() {
      const _this = this
      _this.UserListloading = true
      _this.checkeduserList = []
      _this.UserList = []
      this.userListParas.UserName = ''
      this.userListParas.RoleID = -1
      this.userListParas.DeptID = -1
      this.userListParas.QueryType = this.useracttiveid // 搜索类型 1:全部成员 2:禁用成员 3:未分配部门成员 4:历史成员账号
      $request.GetUserList(_this.userListParas).then(res => {
        _this.UserListloading = false
        if (res.RetCode === '0') {
          _this.UserList = res.Message
        }
      })
    },
    // 获取部门人员信息
    getUserdeptList() {
      const _this = this
      _this.UserdeptListloading = true
      _this.checkeduserList = []
      _this.UserdeptList = []
      this.userdeptListParas.DeptID = this.deptacttiveid
      this.userdeptListParas.QueryType = this.useracttiveid // 搜索类型 1:全部成员 2:禁用成员 3:未分配部门成员 4:历史成员账号
      $request.GetUserList(_this.userdeptListParas).then(res => {
        _this.UserdeptListloading = false
        if (res.RetCode === '0') {
          _this.UserdeptList = res.Message
        }
      })
    },
    // 根据角色获取人员信息
    getUserroleList() {
      const _this = this
      _this.UserListloading = true
      _this.checkeduserList = []
      _this.UserList = []
      this.userListParas.UserName = ''
      this.userListParas.RoleID = this.roleacttiveid
      this.userListParas.QueryType = this.useracttiveid // 搜索类型 1全部成员 2:禁用:成员 3:未分配部门成员 4:历史成员账号
      $request.GetUserList(_this.userListParas).then(res => {
        _this.UserListloading = false
        if (res.RetCode === '0') {
          _this.UserList = res.Message
        }
      })
    },
    // 获取部门数据接口  _type 1:左侧部门列表 2 新增人员部门列表
    getdeptList(_type = 1) {
      const _this = this
      $request.GetDeptList().then(res => {
        if (res.RetCode === '0') {
          _this.deptdata = res.Message
          // _this.usercheckeddeptarr.forEach((ele) => {
          //   ele.sourceData = res.Message
          // })
          // switch (_type) {
          //   case 1:
          //     _this.deptdata = res.Message
          //     break
          //   case 2:
          //     _this.usercheckeddeptarr.forEach((ele) => {
          //       ele.sourceData = res.Message
          //     })
          //     break
          //   default: _this.deptdata = res.Message
          // }
        }
      })
    },
    // 选中部门
    deptchecked(val, data) {
      this.useracttiveid = 0
      this.useracttiveInfo = null
      this.roleacttiveid = 0
      this.roleacttiveInfo = null
      this.deptacttiveid = val
      this.deptacttiveInfo = data
      this.getUserdeptList()
    },
    // 修改人员部门职位
    updateUserdeptPosition(val, row) {
      const _this = this
      if (val !== row.UserIdentity) {
        _this.UserdeptListloading = true
        $request.UpdateDeptPosition({ deptID: this.deptacttiveid, userID: row.UserID, userIdentity: val }).then(res => {
          _this.UserdeptListloading = false
          if (res.RetCode === '0') {
            row.UserIdentity = val
            _this.$message('操作成功')
          } else {
            _this.$message('操作失败：' + res.RetMsg)
          }
        })
      }
    },
    // 获取 1 列表 2 新增人员列表
    getRoledata(_type = 1) {
      const _this = this
      $request.GetRoleList({ pageIndex: 1, pageSize: -1 }).then(res => {
        if (res.RetCode === '0') {
          _this.roleUserData = res.Message
          _this.userrolesDataList = res.Message
          // switch (_type) {
          //   case 1:
          //     _this.roleUserData = res.Message
          //     break
          //   case 2:
          //     _this.userrolesDataList = res.Message
          //     break
          //   default: _this.roleUserData = res.Message
          // }
        }
      })
    },
    // 选中角色
    CheckedroleType(_id, data) {
      this.useracttiveid = 0
      this.useracttiveInfo = null
      this.deptacttiveid = 0
      this.deptacttiveInfo = null
      this.roleacttiveid = _id
      this.roleacttiveInfo = data
      this.getUserroleList()
    },
    // 编辑角色
    editRoleInfo() {
      const _this = this
      _this.fullscreenLoading = true
      $request.SubmitRole(this.roleInfo).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getRoledata()
          _this.addroleVisible = false
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 编辑部门
    editdeptInfo() {
      const _this = this
      _this.fullscreenLoading = true
      $request.SubmitDept(this.deptinfo).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getdeptList()
          _this.adddeptVisible = false
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 打开新增人员 adduserVisible = true
    openaddUser(row = null) {
      const _this = this
      // 初始化选择部门的信息
      _this.userrolesDataList = _this.roleUserData
      if (!row.UserID) {
        _this.initaddUser()
      } else {
        _this.editUserInfo.boxtitile = '人员信息'
        _this.editUserInfo.IsEdit = false
        // 根据id获取人员信息
        _this.userrowclick(row)
      }
      _this.isshowpassword = true
    },
    // 初始化新增人员框
    initaddUser() {
      const _this = this
      _this.editUserInfo.boxtitile = '新增人员'
      _this.editUserInfo.IsEdit = true
      // 初始化部门信息
      _this.usercheckeddeptarr = [{
        deptvalue: null,
        sourceData: _this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      },
      {
        deptvalue: null,
        sourceData: _this.deptdata,
        userdepttype: 0,
        userdepthandleChange: 'userdepthandleChange'
      }]
      // 初始化人员
      _this.userInfo = {
        UserID: 0,
        NickName: '', // 人员名称
        UserName: '', // 账号
        ExeID: '', // 用户编号
        Pwd: '123456',
        repwd: '123456',
        EMail: '',
        Mobile: '',
        Sex: null,
        JobName: '',
        usernum: '',
        OfficeTel: '', // 办公电话
        QQ: '', // qq号
        WorkNum: '', // 工号
        PermissionGroupInfoList: [], // 部门
        IsValid: 1, // 是否启用(1：启用 0：不启用)
        RoleList: [],
        LeaderUserID: 0, // 直属领导,
        LeaderUserName: '', // 直属领导
        UserClass: 2
      }
      _this.adduserVisible = true
    },
    // 打开新增角色框 初始化
    openaddroleUser(_type = 1) {
      const _this = this
      _this.roleInfo = {
        IDX: 0,
        RoleName: '',
        RoleDesc: '',
        UserList: [
          // {
          //   UserID: 119590,
          //   username: 'monica'
          // },
          // {
          //   UserID: 119580,
          //   username: 'phoebe'
          // }
        ] // 角色下的人员
      }
      _this.addroleVisible = true
    },
    // 打开新增部门框 初始化
    openadddeptUser() {
      const _this = this
      _this.deptinfo = {
        GroupName: '',
        GroupDesc: '',
        SimsSectionID: '', // 部门编号
        DepartmentLeadersID: 0, // 部门主管
        DepartmentLeadersName: '', // 名称
        ChargeLeaderID: 0, // 分管领导
        ChargeLeaderName: '', // 分管领导
        AllowAt: 0,
        IsDefault: 0,
        ParentID: 0 // 上级部门
      }
      _this.adddeptVisible = true
    },
    // 根据id获取部门信息
    getdeptinfoById(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $request.GetDeptById({ id: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.deptinfo = res.Message
          _this.adddeptVisible = true
        }
      })
    },
    // 根据id获取人员信息
    getuserinfoById(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $request.GetUserByUserId({ userId: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.userInfo = res.Message
          _this.seletUserVisible = true
        }
      })
    },
    // 根据id获取角色信息
    getroleinfoById(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $request.GetRoleById({ roleId: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.roleInfo = res.Message
          _this.addroleVisible = true
        }
      })
    },
    // 编辑人员
    edituserInfo() {
      const _this = this
      let isgoing = true
      this.$refs['userruleForm'].validate((valid) => {
        if (!valid) {
          isgoing = false
          console.log('error submit!!')
          return false
        }
      })
      if (isgoing) {
        _this.fullscreenLoading = true
        // 处理部门数据
        _this.userInfo.PermissionGroupInfoList = []
        this.usercheckeddeptarr.forEach((ele) => {
          if (ele.deptvalue) {
            _this.userInfo.PermissionGroupInfoList.push({ IDX: parseInt(ele.deptvalue[ele.deptvalue.length - 1]), UserIdentity: ele.userdepttype })
          }
        })
        $request.SubmitUser(this.userInfo).then(res => {
          _this.fullscreenLoading = false
          if (res.RetCode === '0') {
            _this.getUserList()
            _this.adduserVisible = false
            _this.$message('操作成功')
          } else {
            _this.$message('操作失败：' + res.RetMsg)
          }
        })
      }
    },
    // 确认删除角色
    confirmdeleteRoleByid(_idx) {
      const _this = this
      _this.$confirm('确认删除所选角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deleteRoleByid(_idx)
      }).catch(() => {
      })
    },
    // 删除角色
    deleteRoleByid(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $request.DeletePermissionRoleInfo({ IDX: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getRoledata()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 确认删除部门
    confirmdeletdeptByid(_idx) {
      const _this = this
      _this.$confirm('确认删除所选部门?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.deletdeptByid(_idx)
      }).catch(() => {
      })
    },
    // 删除部门
    deletdeptByid(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $request.DeleteDept({ IDX: _idx }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getdeptList()
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败：' + res.RetMsg)
        }
      })
    },
    // 添加子部门
    addchildDept(_idx) {
      const _this = this
      _this.deptinfo = {
        GroupName: '',
        GroupDesc: '',
        SimsSectionID: '', // 部门编号
        DepartmentLeadersID: 0, // 部门主管
        DepartmentLeadersName: '', // 名称
        ChargeLeaderID: 0, // 分管领导
        ChargeLeaderName: '', // 分管领导
        AllowAt: 0,
        IsDefault: 0,
        ParentID: _idx // 上级部门
      }
      _this.adddeptVisible = true
    },
    // 获取选择人员时 ，成员列表 _type 选择人员类型 1： 角色添加人员 2 部门添加人员 3：新增角色时 4：新增部门时 部门主管 5: 新增部门时 分管领导 6：新增人员时，直属领导
    openconnUser(_type) {
      const _this = this
      _this.isshowpassword = true
      _this.selectuserType = _type
      _this.checkeduserIdList = [] // 清空选择的人员项
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $request.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    }
  }, // methods ends
  filters: {
    userdeptTypeName: function(value) {
      switch (value) {
        case 0:
          return '成员'
        case 1:
          return '部门主管'
        case 2:
          return '分管领导'
        default: return ''
      }
    },
    userGenderName: function(value) {
      switch (value) {
        case 0:
          return '男'
        case 1:
          return '女'
        default: return ''
      }
    }
  } // filters ends
}
export default contactsIndex
