<template>
  <div class="HeightControl">
    <!-- <PageHeader :border="false" title="通讯录" center /> -->
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <PageHeader title="通讯录" />
      <div class="flex">
        <div class="flex column p-left-20" style="flex:1">
          <div class="lefttop">
            <el-input
              v-model="userKeywords"
              size="small"
              placeholder="搜索通讯录成员"
              prefix-icon="el-icon-search"
            />
            <div class="left">
              <el-tabs v-model="activeName" :stretch="true" @tab-click="handleClick">
                <el-tab-pane label="成员" name="first" />
                <el-tab-pane label="部门" name="third" />
                <el-tab-pane label="角色" name="second" />
              </el-tabs>
            </div>
            <div v-if="activeName === 'first'" class="allUser m-bottom-10">
              <div v-for="(item, index) in allUserData" :key="index" :class="item.id === useracttiveid? 'user userBack':'user'" @click="CheckedUserType(item.id)">
                <span class="userSpan"><i class="el-icon-user usericon" />{{ item.name }}</span>
                <!-- <span class="userSpanright">{{ item.count }}/{{ item.allcount }}</span>
                <el-progress v-if="item.id === 1? true:false" :format="formatUserprecent" :percentage="(item.count/item.allcount)*100" /> -->
              </div>
            </div>
            <div v-if="activeName === 'second'" class="roles">
              <div class="addrole" @click="openaddroleUser"><i class="el-icon-circle-plus" /> 添加新角色</div>
              <div v-for="(item, index) in roleUserData" :key="index" :class="item.IDX === roleacttiveid? 'role roleBack':'role'" @click="CheckedroleType(item.IDX, item)">
                <span class="roleSpan"><i class="el-icon-user" />{{ item.RoleName }}</span>
                <span class="userSpanright">{{ item.RoleMemberCount }}</span>
              </div>
            </div>
          <div v-if="activeName === 'third'" class="leftbottom">
            <div class="addrole" @click="openadddeptUser"><i class="el-icon-circle-plus" /> 添加新部门</div>
            <div class="dept">
              <el-tree
                ref="tree"
                :data="deptdata"
                node-key="value"
                highlight-current
              >
                <span slot-scope="{ node, data }" style="width:100%" @click="deptchecked(data.value ,data)">
                  <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                  <span class="deptnamespan">
                    {{ node.label }}</span>
                  <span class="deptcountspan">
                    {{ data.count }}</span>
                </span>
              </el-tree>
            </div>
          </div>
          </div>
        </div>
        <div class="righttable p-right-20">
          <div class="listHeader p-tb-10 t-right">
            <div v-if="useracttiveid > 0">
              <div class="ModelBttton1-white-30 m-right-10"><i class="el-icon-download" /> 导出</div>
              <div class="ModelBttton1-blue-30" @click="openaddUser"><i class="el-icon-user" /> 新增成员</div>
            </div>
            <div v-if="deptacttiveid > 0">
              <div class="ModelBttton1-white-30 m-right-10" @click="openconnUser(2)"><i class="el-icon-user" /> 添加成员</div>
              <div class="ModelBttton1-white-30 m-right-10"><i class="el-icon-download" /> 导出</div>
              <div class="ModelBttton1-white-30 m-right-10" @click="dealDeptPower()"><i class="el-icon-setting" /> 编辑权限</div>
              <div class="ModelBttton1-white-30 m-right-10" @click="getdeptinfoById(deptacttiveid)"><i class="el-icon-setting" /> 编辑部门</div>
              <div class="ModelBttton1-white-30 m-right-10" @click="confirmdeletdeptByid(deptacttiveid)"><i class="el-icon-delete" /> 删除部门</div>
              <div class="ModelBttton1-white-30" @click="addchildDept(deptacttiveid)"><i class="el-icon-circle-plus" />添加子部门</div>
            </div>
            <div v-if="roleacttiveid > 0">
              <div class="ModelBttton1-white-30 m-right-10" @click="openconnUser(1)"><i class="el-icon-user" /> 添加成员</div>
              <div class="ModelBttton1-white-30 m-right-10"><i class="el-icon-download" /> 导出</div>
              <div class="ModelBttton1-white-30 m-right-10" @click="dealRolePower()"><i class="el-icon-setting" /> 编辑权限</div>
              <div class="ModelBttton1-white-30 m-right-10" @click="getroleinfoById(roleacttiveid)"><i class="el-icon-setting" /> 编辑角色</div>
              <div class="ModelBttton1-white-30" @click="confirmdeleteRoleByid(roleacttiveid)"><i class="el-icon-delete" /> 删除角色</div>
            </div>
          </div>
          <div v-if="useracttiveid > 0 && checkeduserList.length > 0" class="userbottom t-right b_line_s p-bottom-10">
            <!-- <el-button size="mini">删除选中</el-button>
                  <el-button size="mini">清空通讯录</el-button> -->
            <el-button size="mini" @click="dealenableUser(1,0)"><i class="el-icon-success" />启用</el-button>
            <el-button size="mini" @click="dealenableUser(0,0)"><i class="el-icon-remove" />禁用</el-button>
          </div>
          <div v-if="(deptacttiveid > 0 || roleacttiveid > 0) && checkeduserList.length > 0" class="userbottom t-right b_line_s p-bottom-10">
            <el-button size="mini" @click="dealremoveuser(1, 0)">移除选中</el-button>
            <el-button size="mini" @click="dealremoveuser(2, 0)">移除全部</el-button>
            <el-button size="mini" @click="dealenableUser(1, 0)"><i class="el-icon-success" />启用</el-button>
            <el-button size="mini" @click="dealenableUser(0, 0)"><i class="el-icon-remove" />禁用</el-button>
          </div>
          <el-table
            v-if="useracttiveid > 0 || roleacttiveid > 0"
            ref="UserListTable"
            v-loading="UserListloading"
            :data="UserList"
            :row-class-name="usertableRowClassName"
            style="width: 100%"
            @row-click="openaddUser"
            @selection-change="userlisthandleSelectionChange"
          >
            <el-table-column
              type="selection"
            />
            <el-table-column
              prop="NickName"
              label="成员"
            />
            <el-table-column
              prop="UserClassString"
              label="成员身份"
            />
            <el-table-column
              prop="PermissionGroupInfoList"
              label="部门"
            >
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.PermissionGroupInfoList" :key="index" style="float:left">
                  <span v-if="index>0" style="margin-left:5px">|</span>
                  {{ item.GroupName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="Mobile"
              label="联系电话"
            />
            <el-table-column
              prop="UserName"
              label="账号"
            />
            <el-table-column
              prop="IsValid"
              label=""
            >
              <template slot-scope="scope">
                <span v-if="scope.row.IsValid === 0" class="userstatus"><i class="el-icon-remove" />禁用</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="操作"
              width="50"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="left"
                  trigger="hover"
                  :width="50"
                  popper-class="test_pop_view"
                >
                  <div class="DropDownList">
                    <div class="OperationButton">
                      <el-link :underline="false" @click="userrowclick(scope.row)"><i class="el-icon-view p-right-10" />查看</el-link>
                    </div>
                    <div v-if="roleacttiveid > 0" class="OperationButton">
                      <el-link :underline="false" @click="dealremoveuser(1, scope.row.UserID)"><i class="el-icon-delete p-right-10" />移出</el-link>
                    </div>
                    <div v-if="scope.row.IsValid === 1 && scope.row.UserClass !== 1" class="OperationButton">
                      <el-link :underline="false" @click="dealenableUser(0, scope.row.UserID)"><i class="el-icon-remove p-right-5" />禁用</el-link>
                    </div>
                    <div v-if="scope.row.IsValid === 0" class="OperationButton">
                      <el-link :underline="false" @click="dealenableUser(1, scope.row.UserID)"><i class="el-icon-success p-right-5" />启用</el-link>
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-more" />
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="deptacttiveid > 0"
            ref="UserdeptTable"
            v-loading="UserdeptListloading"
            :data="UserdeptList"
            :row-class-name="usertableRowClassName"
            style="width: 100%"
            @row-click="openaddUser"
            @selection-change="userlisthandleSelectionChange"
          >
            <el-table-column
              type="selection"
            />
            <el-table-column
              prop="NickName"
              label="成员"
              width="180"
            />
            <el-table-column
              prop="UserClassString"
              label="成员身份"
            />
            <el-table-column
              prop="Position"
              label="职位"
            >
              <template slot-scope="scope">
                {{ scope.row.Position }}
                <el-popover
                  class="setdeptbutom"
                  placement="top"
                  trigger="hover"
                  width="60px"
                >
                  <div style="text-align: right; margin: 0;height:100px">
                    <div :class="scope.row.UserIdentity === 0?'setdeptuser checkedpoistioncolor':'setdeptuser'" @click="updateUserdeptPosition(0, scope.row)"><span>普通成员</span><span v-if="scope.row.UserIdentity === 0" class="setdeptusericon"><i class="el-icon-check" /></span></div>
                    <div :class="scope.row.UserIdentity === 1?'setdeptuser checkedpoistioncolor':'setdeptuser'" @click="updateUserdeptPosition(1, scope.row)"><span>部门领导</span><span v-if="scope.row.UserIdentity === 1" class="setdeptusericon"><i class="el-icon-check" /></span></div>
                    <div :class="scope.row.UserIdentity === 2?'setdeptuser checkedpoistioncolor':'setdeptuser'" @click="updateUserdeptPosition(2, scope.row)"><span>分管领导</span><span v-if="scope.row.UserIdentity === 2" class="setdeptusericon"><i class="el-icon-check" /></span></div>
                  </div>
                  <i slot="reference" class="el-icon-caret-bottom m-left-10" />
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="Mobile"
              label="联系电话"
            />
            <el-table-column
              prop="UserName"
              label="账号"
            />
            <el-table-column
              prop="IsValid"
              label=""
            >
              <template slot-scope="scope">
                <span v-if="scope.row.IsValid === 0" class="userstatus"><i class="el-icon-remove" />禁用</span>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="操作"
            >
              <template slot-scope="scope">
                <el-popover
                  placement="left"
                  trigger="hover"
                  :width="50"
                  popper-class="test_pop_view"
                >
                  <div>
                    <div class="m-tb-10 rowoptions">
                      <span><i class="el-icon-view p-right-10" /><el-link :underline="false" @click="userrowclick(scope.row)">查看</el-link></span>
                    </div>
                    <div class="m-tb-10 rowoptions">
                      <span><i class="el-icon-delete p-right-10" /><el-link :underline="false" @click="dealremoveuser(1, scope.row.UserID)">移出</el-link></span>
                    </div>
                    <div v-if="scope.row.IsValid === 1 && scope.row.UserClass !== 1" class="m-tb-10 rowoptions">
                      <span class="userstatus"><i class="el-icon-success p-right-5" /><el-link :underline="false" @click="dealenableUser(0, scope.row.UserID)">禁用</el-link></span>
                    </div>
                    <div v-if="scope.row.IsValid === 0" class="m-tb-10 rowoptions">
                      <span><i class="el-icon-success p-right-5" /><el-link :underline="false" @click="dealenableUser(1, scope.row.UserID)">启用</el-link></span>
                    </div>
                  </div>
                  <i slot="reference" class="el-icon-more" />
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <!-- 新增角色 -->
    <el-dialog class="DepartmentBox" title="新增角色" center :visible.sync="addroleVisible" width="400px" :close-on-click-modal="true">
      <div class="rolebody">
        <el-form :model="roleInfo" :inline="false" label-width="110px">
          <el-form-item label="角色名称">
            <el-input v-model="roleInfo.RoleName" autocomplete="off" size="mini" />
          </el-form-item>
          <el-form-item label="角色描述">
            <el-input v-model="roleInfo.RoleDesc" autocomplete="off" size="mini" />
          </el-form-item>
        </el-form>
        添加该角色成员
        <div class="roleuser">
          <el-button class="adduserbuton" size="small" icon="el-icon-plus" circle @click="openconnUser(3)" />
          <el-tooltip v-for="(item, index) in roleInfo.UserList" :key="index" class="item" effect="dark" :content="item.NickName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="removeUser(index)">x</span>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div slot="footer" class="caozuo">
        <el-button @click="addroleVisible = false">取 消</el-button>
        <el-button type="primary" @click="editRoleInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 添加新部门 -->
    <el-dialog class="DepartmentBox" title="添加新部门" center :visible.sync="adddeptVisible" width="30%" :close-on-click-modal="false">
      <el-form class="NewDepartment" :model="deptinfo" :inline="false" label-width="100px">
        <el-form-item label="部门名称">
          <el-input v-model="deptinfo.GroupName" size="mini" autocomplete="off" />
        </el-form-item>
        <el-form-item label="部门描述" class="miaoshu">
          <el-input v-model="deptinfo.GroupDesc" type="textarea" size="mini" autocomplete="off" class="roleremakr" />
        </el-form-item>
        <el-form-item label="本地部门编号">
          <el-input v-model="deptinfo.SimsSectionID" size="mini" autocomplete="off" />
        </el-form-item>
        <div class="roleuser adddeptbox">
          部门主管<br>
          <el-button class="adduserbuton" size="medium" icon="el-icon-plus" circle @click="openconnUser(4)" />
          <el-tooltip v-if="deptinfo.DepartmentLeadersID > 0" class="item" effect="dark" :content="deptinfo.DepartmentLeadersName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="deptinfo.DepartmentLeadersID = 0">x</span>
            </div>
          </el-tooltip>
        </div>
        <div class="roleuser adddeptbox">
          分管领导<br>
          <el-button class="adduserbuton" size="medium" icon="el-icon-plus" circle @click="openconnUser(5)" />
          <el-tooltip v-if="deptinfo.ChargeLeaderID > 0" class="item" effect="dark" :content="deptinfo.ChargeLeaderName" placement="bottom">
            <div class="addedUser">
              <i class="el-icon-user" />
              <span class="close" @click="deptinfo.ChargeLeaderID = 0">x</span>
            </div>
          </el-tooltip>
        </div>
        <el-form-item label="是否被@" label-width="80px" class="adddeptbox">
          <el-radio v-model="deptinfo.AllowAt" :label="1">是</el-radio>
          <el-radio v-model="deptinfo.AllowAt" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="默认通知" label-width="80px" class="adddeptbox">
          <el-radio v-model="deptinfo.IsDefault" :label="1">是</el-radio>
          <el-radio v-model="deptinfo.IsDefault" :label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="caozuo t-right">
        <el-button @click="adddeptVisible = false">取 消</el-button>
        <el-button type="primary" @click="editdeptInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑人员 -->
    <el-dialog class="adduserdia DepartmentBox" :title="editUserInfo.boxtitile" center :visible.sync="adduserVisible" width="30%" :close-on-click-modal="false">
      <el-form ref="userruleForm" :model="userInfo" :inline="false" style="margin-top:20px" :rules="rules" label-width="90px">
        <el-form-item label="账号" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.UserName" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit" class="m-left-10">{{ userInfo.UserName }}</span>
        </el-form-item>
        <el-form-item v-if="editUserInfo.IsEdit && isshowpassword" label="密码" size="mini" class="b_line p-bottom-10">
          <el-input v-model="userInfo.Pwd" autocomplete="off" />
        </el-form-item>
        <el-form-item v-if="!editUserInfo.IsEdit" label="密码" size="mini" class="b_line p-bottom-10">
          <el-input v-model="userInfo.Pwd" class="m-left-10" style="width:60%" placeholder="********">
            <template slot="append">
              <span style="cursor: pointer" @click="()=> { editUserInfo.IsEdit = true; isshowpassword = true}">重置密码</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="editUserInfo.IsEdit && isshowpassword" label="确认密码" prop="repwd" size="mini" class="b_line p-bottom-10">
          <el-input v-model="userInfo.repwd" />
        </el-form-item>
        <!-- <div class="adduserinfoTips">
          员工创建成功后账号密码以邮件方式发送到该员工联系邮箱<br>请核对员工账号联系邮箱</div> -->
        <el-form-item label="姓名" prop="NickName" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.NickName" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.NickName }}</span>
        </el-form-item>
        <el-form-item label="Email" prop="EMail" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.EMail" size="mini" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.EMail }}</span>
        </el-form-item>
        <el-form-item label="手机" prop="Mobile" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.Mobile" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.Mobile }}</span>
        </el-form-item>
        <el-form-item label="性别" prop="Sex" size="mini" class="b_line p-bottom-10">
          <el-select v-if="editUserInfo.IsEdit" v-model="userInfo.Sex" placeholder="请选择性别">
            <el-option label="男" :value="0" />
            <el-option label="女" :value="1" />
          </el-select>
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.Sex | userGenderName }}</span>
        </el-form-item>
        <el-form-item label="职务描述" prop="JobName" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.JobName" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.JobName }}</span>
        </el-form-item>
        <el-form-item label="用户编号" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.ExeID" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.ExeID }}</span>
        </el-form-item>
        <el-form-item label="办公电话" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.OfficeTel" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.OfficeTel }}</span>
        </el-form-item>
        <el-form-item label="qq号码" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.QQ" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.QQ }}</span>
        </el-form-item>
        <el-form-item label="工号" size="mini" class="b_line p-bottom-10">
          <el-input v-if="editUserInfo.IsEdit" v-model="userInfo.WorkNum" autocomplete="off" />
          <span v-if="!editUserInfo.IsEdit">{{ userInfo.WorkNum }}</span>
        </el-form-item>
        <el-form-item v-if="editUserInfo.IsEdit" label="部门" size="mini" class="b_line p-bottom-10 hauto">
          <div v-for="(item, index) in usercheckeddeptarr" :key="index">
            <el-cascader
              v-model="item.deptvalue"
              style="width:55%"
              :options="item.sourceData"
              @change="userdepthandleChange(item.deptvalue,index)"
            >
              <template slot-scope="{ data }">
                <span>{{ data.label }}</span>
              </template>
            </el-cascader>
            <div style="width:40%;float:right">
              <el-select v-model="item.userdepttype" placeholder="请选择职务" style="width:80%;float:left">
                <el-option label="成员" :value="0" />
                <el-option label="部门主管" :value="1" />
                <el-option label="分管领导" :value="2" />
              </el-select>
              <i class="el-icon-delete m-left-5" @click="removesuerdeptarr(index)" />
            </div>
          </div>
          <div class="adduseradddept" style="width:100%" @click="userdeptpush()"><i class="el-icon-circle-plus" /> 添加部门/职位</div>
        </el-form-item>
        <el-form-item v-if="!editUserInfo.IsEdit" label="部门" size="mini">
          <span v-for="(item, index) in userInfo.PermissionGroupInfoList" :key="index" class="deptInfoshow">
            <span v-if="!editUserInfo.IsEdit" class="deptInfoshowleft">{{ item.GroupName }}</span>
            <span v-if="!editUserInfo.IsEdit" class="deptInfoshowright">{{ item.UserIdentity | userdeptTypeName }}</span>
          </span>
        </el-form-item>
        <el-form-item label="直属领导" size="mini" class="p-top-10">
          <div class="roleuser zhishu">
            <el-button v-if="editUserInfo.IsEdit" class="adduserbuton" size="small" icon="el-icon-plus" circle @click="openconnUser(6)" />
            <el-tooltip v-if="userInfo.LeaderUserID > 0" class="item" effect="dark" :content="userInfo.LeaderUserName" placement="bottom">
              <div class="addedUser">
                <i class="el-icon-user" />
                <span v-if="editUserInfo.IsEdit" class="close" @click="userInfo.LeaderUserID = 0">x</span>
              </div>
            </el-tooltip>
          </div>
        </el-form-item>
        <el-form-item label="角色" size="mini">
          <el-select v-if="editUserInfo.IsEdit" v-model="userInfo.userroles" size="mini" multiple placeholder="请选择" @change="userroleschange">
            <el-option
              v-for="(item, index) in userrolesDataList"
              :key="index"
              :label="item.RoleName"
              :value="item.IDX"
            />
          </el-select>
          <div v-if="!editUserInfo.IsEdit">
            <span
              v-for="(item, index) in userInfo.RoleList"
              :key="index"
            >
              <span class="deptInfoshowleft">
                {{ item.RoleName }}
              </span>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="账号类型" label-width="80px">
          <el-radio v-model="userInfo.UserClass" :disabled="!editUserInfo.IsEdit" :label="1">管理员</el-radio>
          <el-radio v-model="userInfo.UserClass" :disabled="!editUserInfo.IsEdit" :label="2">普通</el-radio>
        </el-form-item>
        <el-form-item label="是否启用" label-width="80px">
          <el-radio v-model="userInfo.IsValid" :disabled="!editUserInfo.IsEdit" :label="0">不启用</el-radio>
          <el-radio v-model="userInfo.IsValid" :disabled="!editUserInfo.IsEdit" :label="1">启用</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="editUserInfo.IsEdit" @click="adduserVisible = false">取 消</el-button>
        <el-button v-if="editUserInfo.IsEdit" type="primary" @click="edituserInfo">确 定</el-button>
        <el-button v-if="!editUserInfo.IsEdit" type="primary" @click="() => { editUserInfo.IsEdit= !editUserInfo.IsEdit; isshowpassword = false}">编 辑</el-button>
        <el-button v-if="!editUserInfo.IsEdit" @click="dealUserPower(userInfo)">权限设置</el-button>
      </div>
    </el-dialog>
    <!-- 添加新成员 -->
    <el-dialog title="添加新成员" class="DepartmentBox" center :visible.sync="seletUserVisible" width="25%" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName" @tab-click="selectUseractiveNamehandleClick">
          <el-tab-pane label="成员" name="user" />
          <el-tab-pane v-if="isshow" label="部门" name="dept" />
          <el-tab-pane v-if="isshow" label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserDeptData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data.value,data)" /></span>
            </span>
          </el-tree>
        </div>
        <div v-if="selectUseractiveName === 'role'" class="selectuser">
          <div v-for="(item, index) in selectUserroleData" :key="index" class="selectuserrole">
            <span class="deptnamespan">{{ item.name }}</span>
            <span class="deptcountspan"><el-checkbox v-model="item.ischecked" /></span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import contactsIndex from '@/minxin/contacts/contactsIndex.js'
export default contactsIndex
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/contactsIndex.scss';
</style>
<style lang='scss'>
.userstatus {
  .el-icon-remove {
    color: #9696a5;
  }
}
.el-table .userrowColor {
  color: #c6c6d4;
}
.adduserdia .el-dialog  .el-dialog__body {
  height: 500px;
  overflow: auto;}

.roleuser .el-icon-plus:before {
    color: #f35e07;
}
</style>
